import React, { useCallback, useEffect, useState } from 'react'
import { useSocket } from '../Providers/SocketProvider.js'
import peer from '../Providers/Peer'
import ReactPlayer from 'react-player'
import html2canvas from 'html2canvas'
function ChatRoom() {
    const [userEmail, setUserEmail] = useState("")
    const [userRoom, setUserRoom] = useState("")
    const [isRoomJoined, setIRoomJoined] = useState(false)
    const [remoteSocketId, setRemoteSocketId] = useState(null)
    const [remoteUserEmail, setRemoteEmail] = useState("")
    const [myStream, setMyStream] = useState(null)
    const [cameraStatus, setCameraStatus] = useState(false)
    const [microphoneStatus, setMicroPhoneStatus] = useState(true)
    const [remoteCameraStatus, setRemoteCameraStatus] = useState(false)
    const [remoteMicrophoneStatus, setRemoteMicroPhoneStatus] = useState(true)
    const [remoteStream, setRemoteStream] = useState(null)
    const [isIncomingCall, setIsIncomingCall] = useState(false)
    const [callOffer, setCallOffer] = useState(null)
    const socket = useSocket()

    const submitForm = useCallback(async (e) => {
        e.preventDefault()
        setIRoomJoined(true)
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        setMyStream(stream)
        const videoTrack = stream.getTracks().find(track => track.kind === 'video')
        videoTrack.enabled = false

        const audioTrack = stream.getTracks().find(track => track.kind === 'audio')
        audioTrack.enabled = false

        socket.emit("user:join_room", {
            email: userEmail,
            room: userRoom
        })
    }, [userEmail, userRoom, socket])

    const onOffCamera = useCallback(async () => {
        const videoTrack = myStream.getTracks().find(track => track.kind === 'video')
        if (cameraStatus === false) {
            // enable camera
            videoTrack.enabled = true
            setCameraStatus(true)
        } else {
            // disable camera
            videoTrack.enabled = false
            setCameraStatus(false)
        }
    }, [myStream, cameraStatus, setCameraStatus])

    const onOffMicrophone = useCallback(async () => {
        if (microphoneStatus === false) {
            // turn on microphone
            await myStream.getAudioTracks().forEach(track => track.enabled = true)
            setMicroPhoneStatus(true)
        } else {
            // turn off microphone
            await myStream.getAudioTracks().forEach(track => track.enabled = false)
            setMicroPhoneStatus(false)
        }
    }, [myStream, microphoneStatus, setMicroPhoneStatus])

    const stopScreenShare = useCallback(() => {
        const videoTrack = myStream.getVideoTracks()[0]
        const sender = peer.peer.getSenders().find(sender => sender.track.kind === videoTrack.kind)
        sender.replaceTrack(videoTrack)
    }, [myStream])

    const shareScreen = useCallback(async () => {
        navigator.mediaDevices.getDisplayMedia({
            video: {
                cursor: 'always'
            },
            audio: {
                echoCancellation: true,
                noiseSuppression: true
            }
        }).then(stream => {
            const videoTrack = stream.getVideoTracks()[0]
            videoTrack.onended = () => {
                stopScreenShare()
            }
            const sender = peer.peer.getSenders().find(stream => stream.track.kind === 'video')
            sender.replaceTrack(videoTrack)
        }).catch(err => {
            console.log(`Unable to displaye media`, err);
        })
    }, [stopScreenShare])

    const takeScreenShot = useCallback(() => {
        html2canvas(document.body).then((canvas) => {
            var a = document.createElement('a')
            a.href = canvas.toDataURL('../assets/image/jpeg').replace("image/jpeg", "images/octet-stream")
            a.download = 'screenshot.jpg'
            a.click()
        })
    })

    const handleUseRoomJoined = useCallback(async (details) => {
        const { email, room, socketId } = details
        console.log(`${email} has been joined in room ${room}`);
        setRemoteSocketId(socketId)
        setRemoteEmail(email)
    }, [])

    const handleCallUser = useCallback(async () => {
        const offer = await peer.getOffer()
        socket.emit('user:call', {
            email: userEmail,
            to: remoteSocketId,
            offer: offer
        })
    }, [remoteSocketId, socket])

    const getMediaStreams = () => {
        peer.addTracks(myStream)
    }

    const handleIncomingCall = useCallback(async ({ email, from, offer }) => {
        setRemoteSocketId(from)
        setRemoteEmail(email)
        setIsIncomingCall(true)
        setCallOffer(offer)
        console.log(`Incomming call from ${from}`, offer);
        /*const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        setMyStream(stream)
        const answer = await peer.getAnswer(offer)
        socket.emit('call:accepted', {
            to: from,
            answer: answer
        })*/
    }, [userEmail, socket])

    const handleCallAccepted = useCallback(async ({ from, answer }) => {
        console.log(`Call has been accepted by ${from}`, answer)
        await peer.setLocalDescription(answer)
    }, [])

    const handleCallByUser = useCallback(async (offer) => {
        console.log(offer, "w2e21e2e21e2");
        const answer = await peer.getAnswer(offer)
        setIsIncomingCall(false)
        socket.emit('call:accepted', {
            to: userEmail,
            answer: answer
        })
    }, [socket])

    const handleTrackEventListener = useCallback(async (ev) => {
        const remoteStream = ev.streams;
        setRemoteStream(remoteStream[0])
    }, [])

    const handleNegotiationNeeded = useCallback(async () => {
        const offer = await peer.getOffer()
        socket.emit('peer:nego:needed', {
            offer: offer,
            to: remoteSocketId
        })
    }, [remoteSocketId, socket])

    const handleCallAcceptedNegotiation = useCallback(async ({ from, offer }) => {
        const answer = await peer.getAnswer(offer);
        socket.emit('peer:nego:done', {
            to: from,
            answer: answer
        })
    }, [socket])

    const handleNegotiationFinal = useCallback(async ({ from, answer }) => {
        await peer.setLocalDescription(answer)
    }, [])

    useEffect(() => {
        peer.peer.addEventListener('track', handleTrackEventListener)
        peer.peer.addEventListener('negotiationneeded', handleNegotiationNeeded);
        return () => {
            peer.peer.removeEventListener('track', handleTrackEventListener)
            peer.peer.removeEventListener('negotiationneeded', handleNegotiationNeeded)
        }
    }, [handleTrackEventListener, handleNegotiationNeeded])

    useEffect(() => {
        socket.on('user:room_joined', handleUseRoomJoined)
        socket.on('incomming:call', handleIncomingCall)
        socket.on('call:accepted', handleCallAccepted)
        socket.on('peer:nego:needed', handleCallAcceptedNegotiation)
        socket.on('peer:nego:final', handleNegotiationFinal)
        return () => {
            socket.off('user:room_joined', handleUseRoomJoined)
            socket.off('incomming:call', handleIncomingCall)
            socket.off('call:accepted', handleCallAccepted)
            socket.off('peer:nego:needed', handleCallAcceptedNegotiation)
            socket.off('peer:nego:final', handleNegotiationFinal)
        }
    }, [handleUseRoomJoined, handleIncomingCall, handleCallAccepted, handleCallAcceptedNegotiation, handleNegotiationFinal, socket])

    return (
        <div>
            {
                !isRoomJoined || isRoomJoined === false
                    ?
                    (
                        <header className='roomHeader'>
                            <div className='chatRoomForm'>
                                <form onSubmit={submitForm}>
                                    <div className='roomEmailField'>
                                        <input type='email' className='form-control' required autoComplete='off' placeholder='Enter your email address' onChange={(e) => setUserEmail(e.target.value)} />
                                    </div>
                                    <div className='roomEmailField'>
                                        <input type='room' className='form-control' required autoComplete='off' placeholder='Enter room ID' onChange={(e) => setUserRoom(e.target.value)} />
                                    </div>
                                    <div className='formSubmitArea'>
                                        <button type='submit' className='formSubmitButton'>Join</button>
                                    </div>
                                </form>
                            </div>
                        </header>
                    )
                    :
                    <div>
                        <div className='centerAligned'>
                            <h1>Chat page</h1>
                            <h4>{remoteSocketId ? `Connected` : 'No one joined yet'}</h4>
                            {myStream && <div><button onClick={getMediaStreams} className='formSubmitButton'>Send Stream</button></div>}
                            {isIncomingCall && <div><button onClick={() => handleCallByUser(callOffer)} className='formSubmitButton'>Answer Call</button></div>}
                            {remoteSocketId && !isIncomingCall && <div><button onClick={handleCallUser} className='formSubmitButton'>Call</button></div>}
                            {myStream && <div><button onClick={shareScreen} className='formSubmitButton'>Share screen</button></div>}
                            <div><button onClick={takeScreenShot} className='formSubmitButton'>Take Screenshot</button></div>
                            <div className='mediaTracksElement'>
                                <div className='mediaTracks'>
                                    {
                                        myStream &&
                                        <div className='streamMedia'>
                                            <ReactPlayer playing width="100%" height="100%" muted url={myStream} />
                                            <div className='streamTitle'>{userEmail}</div>
                                            <ul className='stream-controls'>
                                                <li className='cameraIcon'>
                                                    <button type='button' onClick={onOffCamera}>{cameraStatus === false ? "Turn On Camera" : "Turn off Camera"}</button>
                                                </li>
                                                <li className='microphoneIcon'>
                                                    <button type='button' onClick={onOffMicrophone}>{microphoneStatus === false ? "Unmute" : "Mute"}</button>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    {
                                        remoteStream &&
                                        <div className='streamMedia'>
                                            <ReactPlayer playing width="100%" height="100%" muted url={remoteStream} />
                                            <div className='streamTitle'>{remoteUserEmail}</div>
                                            <ul className='stream-controls'>
                                                <li className='cameraIcon'>
                                                    <button type='button'>{remoteCameraStatus === false ? "Turn On Camera" : "Turn off Camera"}</button>
                                                </li>
                                                <li className='microphoneIcon'>
                                                    <button type='button'>{remoteMicrophoneStatus === false ? "Unmute" : "Mute"}</button>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ChatRoom
