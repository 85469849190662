class PeerService {
    constructor() {
        if (!this.peer) {
            this.peer = new RTCPeerConnection({
                iceServers: [
                    {
                        urls: [
                            'stun:stun1.l.google.com:19302',
                            'stun:stunserver.org:3478'
                        ]
                    }
                ]
            })
        }
    }

    async getOffer() {
        if (this.peer) {
            const offer = await this.peer.createOffer()
            await this.peer.setLocalDescription(new RTCSessionDescription(offer))
            return offer
        }
    }

    async getAnswer(offer) {
        if (this.peer) {
            await this.peer.setRemoteDescription(offer)
            const answer = await this.peer.createAnswer(offer);
            await this.peer.setLocalDescription(new RTCSessionDescription(answer))
            return answer
        }
    }

    async addTracks(stream) {
        for (const track of stream.getTracks()) {
            this.peer.addTrack(track, stream)
        }
    }

    async setLocalDescription(answer) {
        if (this.peer) {
            await this.peer.setRemoteDescription(answer)
        }
    }
}

const peer = new PeerService()
export default peer