import ChatRoom from "./Components/ChatRoom";

function App() {
  return (
    <div className="App">
      <ChatRoom />
    </div>
  );
}

export default App;
